'use client'

import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box, AppBar, IconButton, Toolbar, Grid } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

// Configurations
import ThemeConfig from '@/configs/theme'

// Types
import type { AppBarProps } from '@/types/components/template/app-bar'

// Components
const AvatarBarMolecule = dynamic(() => import('@/components/molecules/avatar-bar'))
const DrawerMobileMolecule = dynamic(() => import('@/components/molecules/drawer/mobile'))
const DrawerDesktopMolecule = dynamic(() => import('@/components/molecules/drawer/desktop'))

const AppBarTemplate = (props: AppBarProps) => {
	// Props
	const { lng } = props

	// Variables
	const theme = useTheme()
	const [open, setOpen] = useState<boolean>(true)
	const isMedium = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<>
			<AppBar
				variant="outlined"
				elevation={0}
				color="inherit"
				sx={{
					borderRight: 0,
					borderTop: 0,
					borderLeft: 0,
					...(!isMedium && { px: { xs: 1, sm: 0.5 } }),
					...(isMedium && {
						right: 'inherit',
						...(!open && { zIndex: theme.zIndex.drawer + 1 })
					})
				}}
			>
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)} sx={{ mr: 2 }}>
						<MenuIcon />
					</IconButton>

					<Grid sx={{ flexGrow: 1 }}></Grid>
					<AvatarBarMolecule lng={lng} />
				</Toolbar>
			</AppBar>

			<Box component="nav" flexShrink={{ md: 0 }} sx={{ width: { md: open ? ThemeConfig.drawerWidth : theme.spacing(8) } }}>
				{!isMedium && <DrawerMobileMolecule lng={lng} open={open} setOpen={setOpen} />}
				{isMedium && <DrawerDesktopMolecule lng={lng} open={open} setOpen={setOpen} />}
			</Box>
		</>
	)
}

export default AppBarTemplate
